<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="' '">
          <template v-slot:toolbar>
            <div class="row">
              <b-button
                v-show="checkPermission('CATE_INSERT')"
                variant="primary"
                size="sm"
                @click="showModalAddCate"
                class="font-weight-bolder"
              >
                <i style="font-size: 1rem" class="flaticon2-add-1"></i>Tạo mới
              </b-button>
            </div>
          </template>
          <template v-slot:preview>
            <b-col cols="8" class="mb-5">
              <div class="row">
                <b-input
                  placeholder="Tìm kiếm theo tên"
                  v-model="searchName"
                  class="col-md-4 mr-4"
                  v-on:keyup.enter="searchCate"
                  size="sm"
                />
                <b-input
                  placeholder="Tìm kiếm theo mã"
                  v-model="searchCode"
                  class="col-md-4"
                  v-on:keyup.enter="searchCate"
                  size="sm"
                />
                <b-col md="1">
                  <b-button
                    size="sm"
                    variant="primary"
                    style="width: 70px"
                    @click="searchCate"
                    >Lọc</b-button
                  >
                </b-col>
                <b-col class="ml-6 d-flex">
                  <b-button
                    v-if="show"
                    v-b-tooltip.hover
                    title="Ẩn tất cả danh mục"
                    size="sm"
                    variant="secondary"
                    @click="hideAllCollapse"
                  >
                    <i style="padding-right: 0" class="fa fa-eye-slash"></i>
                  </b-button>
                  <b-button
                    v-else
                    v-b-tooltip.hover
                    title="Hiện tất cả danh mục"
                    size="sm"
                    variant="secondary"
                    @click="showAllCollapse"
                  >
                    <i style="padding-right: 0" class="fa fa-eye"></i>
                  </b-button>
                  <b-button
                    size="sm"
                    class="ml-2"
                    v-b-tooltip.hover
                    title="Reset dữ liệu"
                    @click="resetData"
                    variant="secondary"
                  >
                    <i style="padding-right: 0" class="fa fa-redo"></i>
                  </b-button>
                </b-col>
              </div>
            </b-col>
            <b-modal
              @hide="onHide"
              ref="add-cate-modal"
              hide-footer
              title="Thêm mới danh mục"
            >
              <v-form ref="form" v-model="valid" lazy-validation>
                <div class="w-50">
                  <b-form-group label="Danh mục cha:">
                    <b-form-input
                      v-if="currentInternalCateId"
                      disabled
                      v-model="currentInternalCateName"
                    ></b-form-input>
                    <treeselect
                      v-else
                      :options="listItem"
                      :multiple="false"
                      placeholder="Chọn danh mục cha"
                      :match-keys="['id', 'label']"
                      v-model="selectedParent"
                      :clearable="true"
                      :show-count="true"
                      openDirection="bottom"
                    >
                    </treeselect>
                  </b-form-group>
                </div>
                <div class="w-50">
                  <b-form-group>
                    <template>
                      <span>Tên:</span>
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      v-model.trim="$v.name.$model"
                      placeholder="Nhập tên"
                      size="sm"
                      :state="validateState('name')"
                    ></b-form-input>
                    <b-form-invalid-feedback v-if="!$v.name.required"
                      >Yêu cầu nhập tên danh mục</b-form-invalid-feedback
                    >
                  </b-form-group>
                </div>
                <div class="w-50">
                  <b-form-group>
                    <template>
                      <span>Mã:</span>
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      v-model.trim="$v.code.$model"
                      placeholder="Nhập mã"
                      size="sm"
                      :state="validateState('code')"
                    ></b-form-input>
                    <b-form-invalid-feedback v-if="!$v.code.required"
                      >Yêu cầu nhập mã danh mục</b-form-invalid-feedback
                    >
                  </b-form-group>
                </div>

                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  v-show="checkPermission('CATE_INSERT')"
                  @click="createCategory"
                  >Lưu</b-button
                >
                <b-button
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="hideModalAddCate"
                  >Hủy</b-button
                >
              </v-form>
            </b-modal>
            <b-modal
              ref="update-cate-modal"
              hide-footer
              title="Cập nhật danh mục"
            >
              <v-form ref="form" v-model="valid" lazy-validation>
                <div class="w-50">
                  <b-form-group label="Danh mục cha:">
                    <treeselect
                      :options="listItem"
                      :multiple="false"
                      placeholder="Chọn danh mục cha"
                      :match-keys="['id', 'label']"
                      v-model="selectedParent"
                      :clearable="true"
                      :show-count="true"
                      openDirection="bottom"
                    >
                    </treeselect>
                  </b-form-group>
                </div>
                <div class="w-50">
                  <b-form-group>
                    <template>
                      <span>Tên:</span>
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      v-model="name"
                      placeholder="Nhập tên"
                      size="sm"
                      :state="validateState('name')"
                    ></b-form-input>
                    <b-form-invalid-feedback v-if="!$v.name.required"
                      >Yêu cầu nhập tên danh mục</b-form-invalid-feedback
                    >
                  </b-form-group>
                </div>
                <div class="w-50">
                  <b-form-group aria-describedby="input-3-live-feedback">
                    <template>
                      <span>Mã:</span>
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      v-model="code"
                      placeholder="Nhập mã"
                      size="sm"
                      :state="validateState('code')"
                    ></b-form-input>
                    <b-form-invalid-feedback v-if="!$v.code.required"
                      >Yêu cầu nhập mã danh mục</b-form-invalid-feedback
                    >
                  </b-form-group>
                </div>

                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="updateCategory"
                  v-show="checkPermission('CATE_UPDATE')"
                  >Lưu</b-button
                >
                <b-button
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="hideModalUpdateCate"
                  >Hủy</b-button
                >
              </v-form>
            </b-modal>
            <b-table
              :items="listItem"
              :fields="fields"
              class="col-md-12"
              bordered
              hover
              :busy="onLoading"
            >
              <template v-slot:table-busy>
                <vcl-table :speed="5" :animate="true" :columns="4"></vcl-table>
              </template>
              <template v-slot:cell(stt)="row">
                <span v-text="row.item.stt"></span>
              </template>
              <template v-slot:cell(name)="row">
                <!-- list item lv 0 -->
                <li
                  class="list-level-0"
                  v-b-toggle:[`item-level1-${row.item.id}`]
                >
                  <p v-html="highlightMatchesName(row.item.name)"></p>
                  <span
                    class="categories_quantity"
                    v-if="row.item.children.length"
                  >
                    ({{ row.item.children.length }})
                  </span>
                  <i
                    v-if="row.item.children.length > 0"
                    class="fa fa-angle-down arrow-level-0"
                  ></i>
                </li>
                <b-collapse
                  :ref="`collapse-${row.item.id}`"
                  :id="`item-level1-${row.item.id}`"
                >
                  <ListItemCate
                    v-for="itemLv1 in row.item.children"
                    :key="itemLv1.id"
                    :item="itemLv1"
                    @editItem="editItem"
                    @addChildCate="addChildCate"
                    @showDeleteAlert="showDeleteAlert"
                    :searchName="searchName"
                    ref="collapseAll"
                  >
                  </ListItemCate>
                </b-collapse>
              </template>
              <template v-slot:cell(code)="row">
                <b v-html="highlightMatchesCode(row.item.code)"></b>
              </template>
              <template v-slot:cell(actions)="row">
                <div class="d-flex justify-content-center">
                  <b-dropdown size="sm" no-caret right>
                    <template slot="button-content">
                      <i
                        style="font-size: 1rem"
                        class="flaticon2-settings pr-0"
                      ></i>
                    </template>
                    <b-dropdown-item
                      @click="editItem(row.item)"
                      v-if="checkViewOnly()"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i style="font-size: 10px" class="flaticon2-box-1"></i>
                        &nbsp; Chi tiết
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="editItem(row.item)"
                      v-if="!checkViewOnly()"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i style="font-size: 1rem" class="flaticon2-pen"></i>
                        &nbsp; Chỉnh sửa
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="addChildCate(row.item)"
                      v-show="checkPermission('CATE_INSERT')"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem; color: #1bc5bc !important"
                          class="fas fa-plus"
                        ></i>
                        &nbsp; Thêm danh mục con
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="showDeleteAlert(row.item)"
                      v-show="checkPermission('CATE_DELETE')"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem; color: #d33"
                          class="flaticon2-rubbish-bin-delete-button"
                        ></i>
                        &nbsp; Xóa
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
            <b-row>
              <b-col>
                <p class="mt-3 text-dark" style="font-weight: 500">
                  Tổng số:
                  {{ listItem.length }}
                </p>
              </b-col>
              <b-col style="margin-right: 400px" v-if="false">
                <b-pagination-nav
                  v-show="numberOfPage >= 2"
                  class="custom-pagination"
                  :link-gen="linkGen"
                  :number-of-pages="numberOfPage"
                  use-router
                  @change="fetchData"
                  align="right"
                  first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
                  prev-class="page-item-prev btn btn-icon btn-sm mr-1 my-1"
                  next-class="page-item-next btn btn-icon btn-sm mr-1 my-1"
                  last-class="page-item-last btn btn-icon btn-sm my-1"
                  page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
                ></b-pagination-nav>
              </b-col>
            </b-row>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<style scoped>
.icon {
  padding-top: 7px;
}
.ml-10 {
  margin-left: 5px;
}
.ml-20 {
  margin-left: 10px;
}
.ml-30 {
  margin-left: 15px;
}
.ml-40 {
  margin-left: 20px;
}
.ml-50 {
  margin-left: 25px;
}

.icon:hover {
  background-color: #90c6fc;
}

.container-fluid {
  height: 100%;
  display: table;
  width: 100%;
  padding: 0;
}

.row-fluid {
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

.centering {
  float: none;
  margin: 0 auto;
}

.dropdown-menu li {
  line-height: 15px;
}
.arrow-level-0 {
  position: absolute;
  color: #464e5f;
  right: 30px;
  font-size: 20px;
  top: 5px;
}

.action-level-1 {
  position: absolute;
  top: 1px;
  right: 17px;
}

.action-level-1 i {
  color: #464e5f;
  font-size: 18px;
}

.action-level-1 .arrow-down {
  margin-right: 10px;
}

.action-level-2 {
  position: absolute;
  top: 1px;
  right: 17px;
}

.action-level-2 i {
  color: #464e5f;
  font-size: 18px;
}

.action-level-2 .arrow-down {
  margin-right: 10px;
}

.action-level-3 {
  position: absolute;
  top: 1px;
  right: 17px;
}

.action-level-3 i {
  color: #464e5f;
  font-size: 18px;
}

.action-level-3 .arrow-down {
  margin-right: 10px;
}

.list-level-0 {
  position: relative;
  list-style-type: none;
  font-weight: bolder;
  color: #464e5f;
}

.categories_quantity {
  position: absolute;
  top: 6px;
  right: 50px;
  font-weight: lighter;
}

.list-level-1 {
  list-style-type: decimal;
  margin-left: 10px;
  line-height: 40px;
  position: relative;
}

.list-level-1 .arrow-down-level-1 {
  position: absolute;
  color: #464e5f;
  right: 66px;
  top: 14px;
}

.list-level-2 {
  list-style-type: circle;
  margin-left: 12px;
  line-height: 35px;
  position: relative;
}

.list-level-2 .arrow-down-level-2 {
  position: absolute;
  color: #464e5f;
  right: 66px;
  top: 14px;
}

.list-level-3 {
  list-style-type: square;
  margin-left: 24px;
  line-height: 35px;
  position: relative;
}
.collapsing {
  transition: none !important;
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import localData from '../../../utils/saveDataToLocal';
import Swal from 'sweetalert2';
import ApiService from '@/core/services/api.service';
import { validationMixin } from 'vuelidate';
import { VclTable } from 'vue-content-loading';
import { required } from 'vuelidate/lib/validators';
import ListItemCate from '@/view/components/ListItemCate.vue';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';
import { removeAccents } from '@/utils/common';
import debounce from 'debounce';
import { TIME_TRIGGER } from '@/utils/constants';

export default {
  mixins: [validationMixin],
  data() {
    return {
      searchName: '',
      searchCode: '',
      show: false,
      fields: [
        {
          key: 'stt',
          label: 'STT',
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '6%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'name',
          label: 'Tên',
          tdClass: 'nameCateClass',
          thStyle: { fontWeight: 600, color: '#181c32', width: '45%' },
        },
        {
          key: 'code',
          label: 'Mã',
          thStyle: { fontWeight: 600, color: '#181c32', width: '45%' },
        },
        { key: 'actions', label: '', width: '4%' },
      ],
      listItem: [],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      page: 1,
      numberOfPage: null,
      count: 1,
      listCate: [],
      selectedParent: null,
      name: '',
      code: '',
      valid: true,
      cateId: 0,
      totalRow: 0,
      onLoading: false,
      submitted: false,
      isNew: true,
      currentInternalCateId: null,
      currentInternalCateName: null,
      currentInternalCateCode: null,
    };
  },
  components: {
    KTCodePreview,
    VclTable,
    ListItemCate,
    Treeselect,
  },
  validations: {
    name: { required },
    code: { required },
  },
  created() {
    this.fetchData();
    this.getListInternalCateParent();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Danh mục nội bộ' }]);
    this.show = false;
  },
  methods: {
    resetData() {
      this.searchName = '';
      this.searchCode = '';
      this.fetchData();
    },
    debounceShowAllCollapse: debounce(function () {
      this.showAllCollapse();
    }, TIME_TRIGGER),
    searchCate() {
      this.fetchData();
      if (this.searchName.trim() || this.searchCode.trim()) {
        this.debounceShowAllCollapse();
      }
    },
    showAllCollapse() {
      this.show = true;
      // show collapse lv 1
      for (const item of this.listItem) {
        this.$refs[`collapse-${item.id}`].$data.show = true;
      }
      for (const item of this.$refs.collapseAll) {
        // show collapse lv 2
        if (item.$refs[`collapseAllLv2-${item.item.id}`]) {
          item.$refs[`collapseAllLv2-${item.item.id}`].show = true;
        }
        // show collapse lv 3
        if (
          item.$refs[`collapseAllLv3-${item.item.id}`] &&
          item.$refs[`collapseAllLv3-${item.item.id}`].length
        ) {
          for (const itemlv3 of item.$refs[`collapseAllLv3-${item.item.id}`]) {
            itemlv3.show = true;
          }
        }
        // show collapse lv 4
        if (
          item.$refs[`collapseAllLv4-${item.item.id}`] &&
          item.$refs[`collapseAllLv4-${item.item.id}`].length
        ) {
          for (const itemlv4 of item.$refs[`collapseAllLv4-${item.item.id}`]) {
            itemlv4.show = true;
          }
        }
        // show collapse lv 5
        if (
          item.$refs[`collapseAllLv5-${item.item.id}`] &&
          item.$refs[`collapseAllLv5-${item.item.id}`].length
        ) {
          for (const itemlv5 of item.$refs[`collapseAllLv5-${item.item.id}`]) {
            itemlv5.show = true;
          }
        }
      }
    },
    hideAllCollapse() {
      this.show = false;
      for (const item of this.listItem) {
        this.$refs[`collapse-${item.id}`].$data.show = false;
      }
      for (const item of this.$refs.collapseAll) {
        if (item.$refs[`collapseAllLv2-${item.item.id}`]) {
          item.$refs[`collapseAllLv2-${item.item.id}`].show = false;
        }
        if (
          item.$refs[`collapseAllLv3-${item.item.id}`] &&
          item.$refs[`collapseAllLv3-${item.item.id}`].length
        ) {
          for (const itemlv3 of item.$refs[`collapseAllLv3-${item.item.id}`]) {
            itemlv3.show = false;
          }
        }
        // hide collapse lv 4
        if (
          item.$refs[`collapseAllLv4-${item.item.id}`] &&
          item.$refs[`collapseAllLv4-${item.item.id}`].length
        ) {
          for (const itemlv4 of item.$refs[`collapseAllLv4-${item.item.id}`]) {
            itemlv4.show = false;
          }
        }
        // hide collapse lv 5
        if (
          item.$refs[`collapseAllLv5-${item.item.id}`] &&
          item.$refs[`collapseAllLv5-${item.item.id}`].length
        ) {
          for (const itemlv5 of item.$refs[`collapseAllLv5-${item.item.id}`]) {
            itemlv5.show = false;
          }
        }
      }
    },
    highlightMatchesName(text = '') {
      const searchTerm = this.searchName ? this.searchName.trim() : '';

      const matchExists = removeAccents(text)
        .toLowerCase()
        .includes(removeAccents(searchTerm).toLowerCase());

      if (!matchExists) return text;

      const regex = new RegExp(searchTerm, 'ig');
      return text.replace(
        regex,
        (matchedText) =>
          `<strong style="color: #FF2400">${matchedText}</strong>`,
      );
    },
    highlightMatchesCode(text = '') {
      const searchTerm = this.searchCode ? this.searchCode.trim() : '';

      const matchExists = removeAccents(text)
        .toLowerCase()
        .includes(removeAccents(searchTerm).toLowerCase());

      if (!matchExists) return text;

      const regex = new RegExp(searchTerm, 'ig');
      return text.replace(
        regex,
        (matchedText) =>
          `<strong style="color: #FF2400">${matchedText}</strong>`,
      );
    },
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v[name];
        return $dirty ? !$error : null;
      }
    },
    getListInternalCateParent(parentId = null) {
      this.listCate = [];
      const param = {
        parentId: parentId,
      };
      const paramQuery = {
        params: param,
      };
      ApiService.query(
        'productExternalCategory/get-parent-list',
        paramQuery,
      ).then(({ data }) => {
        this.listCate = data.data.list_caterogy;
      });
    },
    onSearch() {
      this.fetchData();
    },
    fetchData: async function () {
      // this.page = this.$route.query.page;
      // if (!this.page) {
      //   this.page = 1;
      // }
      if (!this.searchCode || !this.searchName) {
        this.show = false;
      }
      const param = {
        // page: this.page,
        // limit: 10,
        name: this.searchName,
        code: this.searchCode,
      };
      const paramQuery = {
        params: param,
      };
      this.onLoading = true;
      ApiService.query('productExternalCategory/get-list-category', paramQuery)
        .then(({ data }) => {
          this.count = 1;
          this.totalRow = data.data.total_row;
          this.numberOfPage = data.data.total_page;
          this.listItem = [];
          data.data.list_caterogy.forEach((element) => {
            if (element.level === 0) {
              const item = {
                id: element.id,
                stt: this.count,
                name: element.name,
                label: element.name,
                code: element.code,
                level: element.level,
                parentId: element.parentId,
                children: element.children,
              };
              this.listItem.push(item);
              this.count++;
            }
          });
          this.onLoading = false;
          if (this.searchName || this.searchCode) {
            this.show = false;
          }
        })
        .catch(() => {
          this.onLoading = false;
        });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    checkViewOnly: function () {
      let count = 0;
      if (localData.checkPermission('CATE_VIEW')) {
        count++;
      }
      if (localData.checkPermission('CATE_UPDATE')) {
        count++;
      }
      if (count === 1) {
        return true;
      } else {
        return false;
      }
    },
    deleteItem: async function (item) {
      const id = item.id;
      ApiService.post(`productExternalCategory/delete/${id}`).then(
        ({ data }) => {
          if (data.status === 1) {
            makeToastSuccess(data.message);
            this.fetchData();
            this.show = false;
          } else {
            makeToastFaile(data.message);
          }
        },
      );
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa danh mục!',
        text: 'Bạn có chắc muốn xóa danh mục này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    editItem: function (item) {
      this.cateId = item.id;
      const parentId = item.parentId;
      this.getCategoryById();
      this.getListInternalCateParent(parentId);
      this.showModalUpdateCate();
    },
    getCategoryById: function () {
      ApiService.get(`productExternalCategory/${this.cateId}`)
        .then(({ data }) => {
          if (data.status === 1) {
            this.name = data.data.name;
            this.code = data.data.code;
            this.selectedParent = data.data.parentId;
          } else {
            makeToastFaile('Tải dữ liệu thất bại!');
          }
        })
        .catch(({ response }) => {
          makeToastFaile(response.$error);
        });
    },
    addChildCate: function (item) {
      this.showModalAddCate();
      this.selectedParent = item.id;
      this.currentInternalCateId = item.id;
      this.currentInternalCateName = item.name;
      this.currentInternalCateCode = item.code;
    },
    onHide(evt) {
      if (evt.trigger === 'backdrop') {
        this.currentInternalCateId = null;
        this.currentInternalCateName = null;
        this.currentInternalCateCode = null;
      }
    },
    showModalAddCate() {
      this.isNew = true;
      this.selectedParent = null;
      this.name = '';
      this.code = '';
      this.$refs['add-cate-modal'].show();
    },
    hideModalAddCate() {
      this.$refs['add-cate-modal'].hide();
      this.currentInternalCateId = null;
      this.currentInternalCateName = null;
      this.currentInternalCateCode = null;
    },
    showModalUpdateCate() {
      this.isNew = true;
      this.$refs['update-cate-modal'].show();
    },
    hideModalUpdateCate() {
      this.$refs['update-cate-modal'].hide();
    },
    createCategory: function () {
      this.isNew = false;
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      const name = this.name;
      const code = this.code;
      const parentId = this.currentInternalCateId
        ? this.currentInternalCateId
        : this.selectedParent;
      const data = {
        code: code,
        name: name,
        parentId: parentId,
      };
      ApiService.post('productExternalCategory/create', data)
        .then(({ data }) => {
          if (data.status === 1) {
            makeToastSuccess(data.message);
            this.fetchData();
            this.hideModalAddCate();
            this.show = false;
          } else {
            makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          makeToastFaile(response.$error);
        });
    },
    updateCategory: async function () {
      this.isNew = false;
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      const name = this.name;
      const code = this.code;
      const data = {
        id: this.cateId,
        name: name,
        code: code,
        parentId: this.selectedParent,
      };
      ApiService.post('productExternalCategory/update', data)
        .then(({ data }) => {
          if (data.status === 1) {
            makeToastSuccess(data.message);
            this.fetchData();
            this.hideModalUpdateCate();
            this.show = false;
          } else {
            makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          makeToastFaile(response.$error);
        });
    },
  },
};
</script>
